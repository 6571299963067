<template>
  <section>
    <top-alternative-section
        :image="image"
        :title="$t('debito.top.title')"
        :content="$t('debito.top.content')"
    ></top-alternative-section>

    <description-section
      :title="$t('debito.description.title')"
      :content="$t('debito.description.content')"
    ></description-section>

    <features-section
      :title="$t('debito.featuresSection.title')"
      :description="$t('debito.featuresSection.description')"
      :items="$t('debito.featuresSection.items')"
    >
    </features-section>

    <offers-section 
      :items="$t('debito.offersSection.items')"
    ></offers-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import DescriptionSection from "../sections/DescriptionSection.vue";
import OffersSection from "../sections/OffersSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import LightImage from "../../assets/credito.png"

export default {
  metaInfo: {
    title: 'Emisor',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Integre todos sus productos de tarjeta en una plataforma única de compensación y liquidación para entornos locales e internacionales.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    TopAlternativeSection,
    DescriptionSection,
    OffersSection,
    FeaturesSection,
    ContactSection
  },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  
<template>
    <section>
        <top-alternative-section
          :image="image"
          :title="$t('aclaraciones.top.title')"
          :content="$t('aclaraciones.top.content')"
        ></top-alternative-section>

        <description-section
          :title="$t('aclaraciones.description.title')"
          :content="$t('aclaraciones.description.content')"
        ></description-section>
          
        <features-section
          :title="$t('aclaraciones.featuresSection.title')"
          :description="$t('aclaraciones.featuresSection.description')"
          :items="$t('aclaraciones.featuresSection.items')"
        >
        </features-section>

        <offers-section 
          :items="$t('aclaraciones.offersSection.items')"
        ></offers-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import DescriptionSection from "../sections/DescriptionSection.vue";
  import OffersSection from "../sections/OffersSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/aclaraciones.png"
  
  export default {
    metaInfo: {
    title: 'Aclaraciones',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Orquestación y procesamiento de extremo a extremo en la gestión de controversias emisor y adquirente.'},
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
    },
    components: {
      TopAlternativeSection,
      DescriptionSection,
      OffersSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  